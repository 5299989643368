import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFluidWebp from "../components/img-fluid-webp"
import ImgFluid from "../components/img-fluid"

import "../scss/site.scss"
import "../scss/service.scss"

const LeistungBaugutachten = () => {
  return (
    <Layout>
      <SEO
        title="Baugutachten | freie ingenieure bau"
        description="Unsere Leistungen: Gerichts-, Privat-, und Schiedsgutachten zu Bauschäden, Beweissicherung, Vorprozessuale Beratung, Qualitätskontrolle u.v.m."
        lang="de"
      />
      <div className="fib-sp80"></div>
      <div className="fib-sp40 uk-visible@m"></div>
      <div className="fib-container-text">
        <div className="uk-grid-medium uk-flex-top" uk-grid="">
          <div className="uk-width-8-12@s uk-width-7-12@m">
            <h2 className="fib-ft54 fib-ft-bold">
              Zusammenhänge er&shy;ken&shy;nen und
              si&shy;tua&shy;tions&shy;gerecht beurteilen.
            </h2>
            <h1 className="fib-ft54 fib-ft-bold fib-ftgrey-light">
              Geprüfte Sachkunde und Unabhängigkeit.
            </h1>
          </div>
          <div className="fib-sp40 uk-hidden@m"></div>
          <div className="uk-width-5-12@m">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@s uk-width-10-12@m">
                <ImgFluid
                  className="fib-services-img-isobox"
                  filename="isobox-building-assessment1.png"
                  alt="freie ingenieure bau - Isobox Baugutachten"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp120 uk-visible@m"></div>
        <div className="fib-sp120"></div>
        <div className="fib-services-divider-blue"></div>
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>

        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-7-12@s">
            <h3 className="fib-services-h3">Leistungen Baugutachten</h3>
            <h4 className="fib-services-h4">
              Gerichts-, Privat-, und Schiedsgutachten zu Bauschäden
            </h4>
            <p>
              Eine Ein&shy;schätzung der Ursache und der notwen&shy;digen
              Sanierungs&shy;maßnahmen ist oft schon bei einem ersten
              Orts&shy;termin möglich. Bei kom&shy;plexeren Sach&shy;verhalten
              bieten wir zusätzlich Bauteil&shy;untersuchungen und Messungen im
              Rahmen eines mehr&shy;stufigen Vor&shy;gehens an. Verwert&shy;bare
              Ergeb&shy;nisse bei angemes&shy;senen Kosten-Nutzen
              Verhält&shy;nis stehen dabei im Fokus unserer Gut&shy;achten zu
              Schäden an Dächern, Balkonen, Fassaden, Fenstern,
              Pfosten-Riegel-Konstruktionen, Abdichtungen u.v.m.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Beweissicherung
            </h4>
            <p>
              Nach Durch&shy;führung baulicher Maßnahmen ergeben sich oft
              Konflikte, da der Ausgangs&shy;zustand nicht mehr objektiv
              feststell&shy;bar ist. Durch unsere un&shy;abhängige
              Beweis&shy;sicherung doku&shy;mentieren wir den Zustand im
              Vor&shy;feld rechts&shy;sicher. Zeit- und kosten&shy;intensive
              Auseinander&shy;setzung sowie ungerecht&shy;fertige
              Schadens&shy;ersatz&shy;ansprüche werden damit von Beginn an
              vermieden.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Vorprozessuale Beratung
            </h4>
            <p>
              In manchen Streit&shy;fällen deutet sich eine gerichtliche
              Auseinander&shy;setzung an. Auf Grund&shy;lage unserer Erfahrung
              aus abge&shy;schlossenen Gerichts&shy;verfahren bewerten wir für
              Sie die Sach&shy;lage im Vor&shy;feld eines Rechts&shy;streits und
              beraten Sie zur Abwägung ihrer Erfolgs&shy;chancen.
            </p>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Qualitätskontrolle
            </h4>
            <p>
              Die Sicher&shy;stellung der Ge&shy;nehmigungs&shy;fähigkeit, die
              Ein&shy;haltung des Zeit&shy;plans sowie die Ver&shy;meidung von
              zeit&shy;intensiven Rück&shy;bauten sind nur einige Vor&shy;teile
              unserer planungs- und aus&shy;führungs&shy;begleitenden
              Qualitäts&shy;kontrolle. Mit konstruk&shy;tiven
              Lösungs&shy;vorschlägen zum gemein&shy;samen Projekt&shy;erfolg.
            </p>

            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Weitere Leistungen
            </h4>
            <ul className="fib-list">
              <li>Bausubstanzbewertung, Technische Due Dilligence</li>
              <li>Messtechnische Prüfungen</li>
              <li>Abnahmebegleitung</li>
            </ul>
            <div style={{ paddingTop: `60px` }}></div>
          </div>

          <div className="uk-width-5-12@s">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@m">
                <ImgFluidWebp
                  filename="img-partner2-portrait.jpg"
                  alt="freie ingenieure bau - Dipl.-Ing. M. Krätschell"
                />
                <div style={{ paddingTop: `20px` }}></div>
                <p className="fib-ft18 fib-ft-bold">Dipl.-Ing. M. Krätschell</p>
                <p className="fib-ft18" style={{ fontStyle: `italic` }}>
                  Öffentlich bestell&shy;ter und ver&shy;eidig&shy;ter
                  Sach&shy;verstän&shy;diger für Schä&shy;den an Gebäu&shy;den
                  und Wärme- sowie Feuchte&shy;schutz,
                  Prüf&shy;sachver&shy;ständiger für ener&shy;getische
                  Ge&shy;bäude&shy;planung
                </p>
                <p className="fib-ft18 fib-ftgrey-dark">
                  <Obfuscate
                    className="fib-text-link fib-ft-blue"
                    email="kraetschell@freie-ingenieure-bau.de"
                  >
                    kraetschell@freie-ingenieure-bau.de
                  </Obfuscate>
                  <br />
                  <span itemProp="telephone">
                    <a
                      className="fib-text-link fib-ft-blue"
                      href="tel:+493061644837"
                    >
                      T +49 30 61644837
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-grid uk-grid-match " uk-grid="">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-thermo1.jpg"
              alt="freie ingenieure bau - Thermograife"
            />
          </div>
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-thermo2.jpg"
              alt="freie ingenieure bau - Thermograife"
            />
          </div>
          <div className="uk-width-6-12@m uk-flex-center uk-flex-left@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `-20px` }}
            >
              Ortung von Wasserein- und -aus&shy;tritts&shy;wegen mittels
              Thermografie
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
          <div className="uk-width-8-12@m">
            <ImgFluidWebp
              filename="baugutachten-schimmel.jpg"
              alt="freie ingenieure bau - Thermograife"
            />
          </div>
          <div className="uk-width-8-12@m uk-text-center@m">
            <p className="fib-ft18" style={{ fontStyle: `italic` }}>
              Schadensanalyse beim Schimmelpilzbefall
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-grid uk-grid-match " uk-grid="">
          <div className="uk-width-4-12@m">
            <ImgFluidWebp
              filename="baugutachten-Blasen1.jpg"
              alt="freie ingenieure bau - Blasenbildung"
            />
          </div>
          <div className="uk-width-4-12@m">
            <ImgFluidWebp
              filename="baugutachten-Blasen2.jpg"
              alt="freie ingenieure bau - Blasenbildung"
            />
          </div>
          <div className="uk-width-4-12@m">
            <ImgFluidWebp
              filename="baugutachten-Blasen3.jpg"
              alt="freie ingenieure bau - Blasenbildung"
            />
          </div>
          <div className="uk-width-12-12@m uk-flex-center uk-flex-left@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `-20px` }}
            >
              Analyse von Blasenbildungen in einer Bodenbeschichtung (osmotische Blasenbildung mit alkalischer Flüssigkeit)
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-grid uk-grid-match " uk-grid="">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Uranin1.jpg"
              alt="freie ingenieure bau - Dichtheitstest"
            />
          </div>
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Uranin2.jpg"
              alt="freie ingenieure bau - Dichtheitstest"
            />
          </div>
          <div className="uk-width-12-12@m uk-flex-center uk-flex-left@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `-20px` }}
            >
              Dichtheitstest an Abdichtungsanschlüsssen mittels Indikatorflüssigkeit (Uranin) und UV-Licht-Lampe
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-grid uk-grid-match " uk-grid="">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Innendaemmung1.jpg"
              alt="freie ingenieure bau - Innendämmung"
            />
          </div>
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Innendaemmung2.jpg"
              alt="freie ingenieure bau - Innendämmung"
            />
          </div>
          <div className="uk-width-12-12@m uk-flex-center uk-flex-left@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `-20px` }}
            >
              Ermittlung von Fehlstellen in einer Innendämmung (Hohllagen und Wärmebrücken)
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Abplatzung1.jpg"
              alt="freie ingenieure bau - Putzabplatzung"
            />
          </div>
          <div className="uk-width-6-12@m uk-text-center@m">
            <p className="fib-ft18" style={{ fontStyle: `italic` }}>
            Analyse von Abplatzungen an Porenbetonplatten
            </p>
          </div>
        </div>

        <div className="fib-sp80"></div>

        <div className="uk-grid uk-grid-match " uk-grid="">
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Luftdichtheit1.jpg"
              alt="freie ingenieure bau - Luftdichtheit"
            />
          </div>
          <div className="uk-width-6-12@m">
            <ImgFluidWebp
              filename="baugutachten-Luftdichtheit2.jpg"
              alt="freie ingenieure bau - Luftdichtheit"
            />
          </div>
          <div className="uk-width-12-12@m uk-flex-center uk-flex-left@m">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `-20px` }}
            >
              &bdquo;aufgeblähte&ldquo; Flachdachabdichtung infolge von Undichtigkeiten in der Dampfsperre (Ergebnis eines &bdquo;Blower-Door-Tests&ldquo;)
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default LeistungBaugutachten
